/*
      ---< Node Utilities >---

 - PolynetNodeObject: nodes interface
 - getNodeLevel: get the node level from CDN
 - validDataMask: generates a mask with valid nodes (non-repeating)
 - cleanUpData: apply the mask over an array
 - checkLinks: check the links and find the broken ones. Set a ghost node for broken links.

 */
// import { Field, Vector } from '@grafana/data';
import { globalFlags } from './globalflags';
import {
  TierDataStructure,
  researchTierDataStructure,
  advanceTierDataStructure,
  liteTierDataStructure,
} from './data/fields';
import { generateTooltipLite, generateTooltipAdvanced, generateTooltipResearch } from 'interface/tooltip';

export interface PolynetNodeObject {
  id?: string | number;
  x?: number;
  y?: number;
  z?: number;
  vx?: number;
  vy?: number;
  vz?: number;
  fx?: number;
  fy?: number;
  fz?: number;

  size: number;
  level: number;

  description: string;
}

export interface GraphDataInterface {
  nodes: any[];
  links: any[];
}

export type graphType = 'research' | 'advance' | 'lite';

// Extract data field by name from Grafana data structure
export function getFieldDataByName({ json, fieldName }: { json: any; fieldName: string }) {
  return json.reduce((newValuesArr: any, currentField: any) => {
    if (currentField.name === fieldName) {
      newValuesArr.push(...currentField.values);
    }
    return newValuesArr;
  }, []);
}

// generate clean fields object for advance and lite tier
export function getCleanedFieldsByTier(tier: graphType, data: any[], cleanDataMask: any[]) {
  let cleanedFields: TierDataStructure = { idValues: [], sourceValues: [] };
  let tierDataStructure = [];

  switch (tier) {
    case 'research':
      tierDataStructure = researchTierDataStructure;
      break;
    case 'advance':
      tierDataStructure = advanceTierDataStructure;
      break;
    default:
      tierDataStructure = liteTierDataStructure;
      break;
  }

  if (tierDataStructure) {
    tierDataStructure.forEach((tierField) => {
      cleanedFields[tierField?.nodeName as keyof typeof cleanedFields] = cleanUpData(
        getFieldDataByName({ json: data, fieldName: tierField?.fieldName }),
        cleanDataMask
      );
    });
  }

  return cleanedFields;
}

// generate final nodes and links for the graph
export function setGraphNodesLinks(cleanedFields: TierDataStructure, graphType: graphType) {
  const gData: GraphDataInterface = { nodes: [], links: [] };

  gData.links = setLinksFromSource(cleanedFields);
  gData.nodes = setNodesFromIdValues(cleanedFields, graphType);
  const ghostNodesLinks = setGhostNodesLinks(cleanedFields, graphType);
  if (ghostNodesLinks.nodes.length && ghostNodesLinks.links.length) {
    gData.links = [...gData.links, ...ghostNodesLinks.links];
    gData.nodes = [...gData.nodes, ...ghostNodesLinks.nodes];
  }

  if (gData.nodes.length > 0) {
    const cdnNode = setCDNNode(graphType);
    gData.nodes = [...gData.nodes, ...cdnNode];
  }

  return gData;
}

function setLinksFromSource(cleanedFields: TierDataStructure) {
  return cleanedFields.sourceValues.map((el, i) => ({
    source: el,
    target: cleanedFields.idValues[i],
  }));
}

function setNodesFromIdValues(cleanedFields: TierDataStructure, graphType: graphType) {
  let nodes = [];

  switch (graphType) {
    case 'research':
      nodes = cleanedFields.idValues.map((el, i) => ({
        id: el,
        abfr: cleanedFields.abfrValues !== undefined ? cleanedFields.abfrValues[i] : '',
        bh: cleanedFields.bufferHealthValues !== undefined ? cleanedFields.bufferHealthValues[i] : '',
        nat: cleanedFields.natType !== undefined ? cleanedFields.natType[i] : '',
        session: cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[i] : '',
        size: globalFlags.nodeSize,
        tooltip: generateTooltipResearch(cleanedFields, i),
        cs: cleanedFields.connectionStatus !== undefined ? cleanedFields.connectionStatus[i] : '',
      }));
      break;
    case 'advance':
      nodes = cleanedFields.idValues.map((el, i) => ({
        id: el,
        abfr: cleanedFields.abfrValues !== undefined ? cleanedFields.abfrValues[i] : '',
        bh: cleanedFields.bufferHealthValues !== undefined ? cleanedFields.bufferHealthValues[i] : '',
        nat: cleanedFields.natType !== undefined ? cleanedFields.natType[i] : '',
        session: cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[i] : '',
        size: globalFlags.nodeSize,
        tooltip: generateTooltipAdvanced(cleanedFields, i),
      }));
      break;
    default:
      nodes = cleanedFields.idValues.map((el, i) => ({
        id: el,
        bh: cleanedFields.bufferHealthValues !== undefined ? cleanedFields.bufferHealthValues[i] : '',
        session: cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[i] : '',
        size: globalFlags.nodeSize,
        tooltip: generateTooltipLite(cleanedFields, i),
      }));
      break;
  }

  return nodes;
}

function setGhostNodesLinks(cleanedFields: TierDataStructure, graphType: graphType) {
  const ghostData: GraphDataInterface = { nodes: [], links: [] };

  if (cleanedFields.sourceValues.includes(globalFlags.ghostId)) {
    switch (graphType) {
      case 'research':
        ghostData.nodes.push({
          id: globalFlags.ghostId,
          abfr: '0',
          bh: '0',
          nat: '0',
          session: globalFlags.ghostId,
          size: globalFlags.ghostNodeSize,
          tooltip: "<p align='center'><b>Ghost Node</b></p> ",
          cs: 'NO_PARENT',
        });
        break;
      case 'advance':
        ghostData.nodes.push({
          id: globalFlags.ghostId,
          abfr: '0',
          bh: '0',
          nat: '0',
          session: globalFlags.ghostId,
          size: globalFlags.ghostNodeSize,
          tooltip: "<p align='center'><b>Ghost Node</b></p> ",
        });
        break;
      default:
        ghostData.nodes.push({
          id: globalFlags.ghostId,
          bh: '0',
          session: globalFlags.ghostId,
          size: globalFlags.ghostNodeSize,
          tooltip: "<p align='center'><b>Ghost Node</b></p> ",
        });
        break;
    }

    ghostData.links.push({
      source: globalFlags.poiId,
      target: globalFlags.ghostId,
    }); // Create its link to CDN
  }

  return ghostData;
}

function setCDNNode(graphType: graphType) {
  let cdnNode = [];
  switch (graphType) {
    case 'research':
      cdnNode.push({
        id: globalFlags.poiId,
        abfr: '0',
        bh: '0',
        nat: '0',
        session: globalFlags.poiId,
        size: globalFlags.poiNodeSize,
        tooltip: "<p align='center'><b>CDN</b></p> ",
        cs: 'NO_PARENT',
      });
      break;
    case 'advance':
      cdnNode.push({
        id: globalFlags.poiId,
        abfr: '0',
        bh: '0',
        nat: '0',
        session: globalFlags.poiId,
        size: globalFlags.poiNodeSize,
        tooltip: "<p align='center'><b>CDN</b></p> ",
      });
      break;
    default:
      cdnNode.push({
        id: globalFlags.poiId,
        bh: '0',
        session: globalFlags.poiId,
        size: globalFlags.poiNodeSize,
        tooltip: "<p align='center'><b>CDN</b></p> ",
      });
      break;
  }

  return cdnNode;
}

// Generates a boolean Mask of non duplicated values
export function validDataMask(idValues: string[] | undefined) {
  const mask: boolean[] = [];
  const checkedValues: string[] = [];

  if (idValues) {
    idValues.forEach((value) => {
      if (checkedValues.includes(value)) {
        mask.push(false);
        return;
      }
      mask.push(true);
      checkedValues.push(value);
    });
  }

  return mask;
}

// Applies a Mask over an Array
export function cleanUpData(dataVector: string[] | undefined, mask: boolean[]) {
  return dataVector?.filter((_, index) => mask[index]) || [];
}

// Check if the Sources exist in the system and generate the Ghost Node and the mask indicating its position
export function checkLinks(idNodes: string[], idSources: string[]): string[] {
  return idSources.map((node: string) => {
    if (idNodes.includes(node) || node === globalFlags.poiId) {
      return node;
    } else {
      return globalFlags.useGhostNode ? globalFlags.ghostId : globalFlags.poiId;
    }
  });
}
