/*
  ---< Query Fields Definition >---
*/
export interface QueryFieldsAdvanced {
  idValues: string[];
  sourceValues: string[];
  abfrValues: string[];
  bufferHealthValues: string[];
  connectionStatus: string[];
  natType: string[];
  uplinkCapacity: string[];
  ip: string[];
  asn: string[];
  asname: string[];
  sessionValues: string[];
}

export interface QueryFieldsLite {
  idValues: string[];
  sourceValues: string[];
  bufferHealthValues: string[];
  sessionValues: string[];
}

export interface QueryFieldsResearch {
  idValues: string[];
  sourceValues: string[];
  abfrValues: string[];
  bufferHealthValues: string[];
  connectionStatus: string[];
  natType: string[];
  uplinkCapacity: string[];
  ip: string[];
  asn: string[];
  asname: string[];
  sessionValues: string[];
}

export const advanceTierDataStructure = [
  {
    nodeName: 'idValues',
    fieldName: 'NodeId',
  },
  {
    nodeName: 'sourceValues',
    fieldName: 'Source',
  },
  {
    nodeName: 'abfrValues',
    fieldName: 'AggregateBufferFillingRate',
  },
  {
    nodeName: 'bufferHealthValues',
    fieldName: 'BufferHealth',
  },
  {
    nodeName: 'connectionStatus',
    fieldName: 'ConnectionStatus',
  },
  {
    nodeName: 'natType',
    fieldName: 'NatType',
  },
  {
    nodeName: 'uplinkCapacity',
    fieldName: 'UplinkCapacity',
  },
  {
    nodeName: 'ip',
    fieldName: 'Ip',
  },
  {
    nodeName: 'asn',
    fieldName: 'Asn',
  },
  {
    nodeName: 'asname',
    fieldName: 'AsName',
  },
  {
    nodeName: 'sessionValues',
    fieldName: 'SessionId',
  },
];

export const liteTierDataStructure = [
  {
    nodeName: 'idValues',
    fieldName: 'NodeId',
  },
  {
    nodeName: 'sourceValues',
    fieldName: 'Source',
  },
  {
    nodeName: 'bufferHealthValues',
    fieldName: 'BufferHealth',
  },
  {
    nodeName: 'sessionValues',
    fieldName: 'SessionId',
  },
];

export const researchTierDataStructure = [
  {
    nodeName: 'idValues',
    fieldName: 'NodeId',
  },
  {
    nodeName: 'sourceValues',
    fieldName: 'Source',
  },
  {
    nodeName: 'abfrValues',
    fieldName: 'AggregateBufferFillingRate',
  },
  {
    nodeName: 'bufferHealthValues',
    fieldName: 'BufferHealth',
  },
  {
    nodeName: 'connectionStatus',
    fieldName: 'ConnectionStatus',
  },
  {
    nodeName: 'natType',
    fieldName: 'NatType',
  },
  {
    nodeName: 'uplinkCapacity',
    fieldName: 'UplinkCapacity',
  },
  {
    nodeName: 'ip',
    fieldName: 'Ip',
  },
  {
    nodeName: 'asn',
    fieldName: 'Asn',
  },
  {
    nodeName: 'asname',
    fieldName: 'AsName',
  },
  {
    nodeName: 'sessionValues',
    fieldName: 'SessionId',
  },
];

export interface TierDataStructure {
  idValues: string[];
  sourceValues: string[];
  abfrValues?: string[];
  bufferHealthValues?: string[];
  connectionStatus?: string[];
  natType?: string[];
  uplinkCapacity?: string[];
  ip?: string[];
  asn?: string[];
  asname?: string[];
  sessionValues?: string[];
}
