/*
    ---< Context and Strategy interface definitions >---

- LinkColorSelector: context class
- LinkColorSelectorStrategy: interface used by the context

- PolynetNodeObject3D: extension of the PolynetNodeObject with metrics
- linkColorSelectorFactory: strategies selector according to metric
- LinkColorABFR: color strategy for "AggregateBufferFillingRate"
- LinkColorBufferHealth: color strategy for "BufferHealth"
- LinkColorNatType: color strategy for "NatType"
- LinkColorConnectionStatus: color strategy for "ConnectionStatus"

*/

import { globalFlags } from './globalflags';
import { PolynetNodeObject3D } from './nodeColorStrategies';

// PolynetLinkObject interface
export interface PolynetLinkObject3D {
  source: PolynetNodeObject3D;
  target: PolynetNodeObject3D;
}

// ---< Context Section >---

// LinkColorSelector context definition
export class LinkColorSelector {
  private colorStrategy: LinkColorSelectorStrategy;

  constructor(strategy: LinkColorSelectorStrategy) {
    this.colorStrategy = strategy;
  }

  setColorStrategy(strategy: LinkColorSelectorStrategy) {
    this.colorStrategy = strategy;
  }

  // Common part to all strategies
  colorFunction(link: PolynetLinkObject3D): string {
    if (!link) {
      return globalFlags.linkErrorColor;
    }
    if (link.target.id === globalFlags.ghostId) {
      return globalFlags.ghostColor;
    }
    return this.colorStrategy.getHexColor(link);
  }
}

// LinkColorSelectorStrategy interface
interface LinkColorSelectorStrategy {
  getHexColor(link: PolynetLinkObject3D): string;
}

// ---< Strategies Section >---
// Edit this section to add more strategies

// Strategy factory selector
export function linkColorSelectorFactory(metric: string): LinkColorSelectorStrategy {
  switch (metric) {
    case 'ABFR':
      return new LinkColorABFR();
    case 'BufferHealth':
      return new LinkColorBufferHealth();
    case 'NatType':
      return new LinkColorNatType();
    case 'ConnectionStatus':
      //console.log('strategy:', metric);
      return new LinkColorConnectionStatus();
    default:
      return new LinkColorBufferHealth();
  }
}

// ABFR
class LinkColorABFR implements LinkColorSelectorStrategy {
  getHexColor(link: PolynetLinkObject3D): string {
    for (const index in globalFlags.aggregateBufferFillingRate) {
      if (link.target.abfr < globalFlags.aggregateBufferFillingRate[index]) {
        return globalFlags.aggregateBufferFillingRateColor[index];
      }
    }
    return globalFlags.aggregateBufferFillingRateColor[globalFlags.aggregateBufferFillingRateColor.length - 1];
  }
}

// BufferHealth
class LinkColorBufferHealth implements LinkColorSelectorStrategy {
  getHexColor(link: PolynetLinkObject3D): string {
    for (const index in globalFlags.bufferHealth) {
      if (link.target.bh < globalFlags.bufferHealth[index]) {
        return globalFlags.bufferHealthColor[index];
      }
    }
    return globalFlags.bufferHealthColor[globalFlags.bufferHealthColor.length - 1];
  }
}

// NatType
class LinkColorNatType implements LinkColorSelectorStrategy {
  getHexColor(link: PolynetLinkObject3D): string {
    for (const index in globalFlags.natType) {
      if (link.target.nat === globalFlags.natType[index]) {
        return globalFlags.natTypeColor[index];
      }
    }
    return globalFlags.natTypeColor[globalFlags.natTypeColor.length - 1];
  }
}

// ConnectionStatus
class LinkColorConnectionStatus implements LinkColorSelectorStrategy {
  getHexColor(link: PolynetLinkObject3D): string {
    const test = JSON.parse(JSON.stringify(link));
    console.log('link:', test, 'source:', test.source, 'target:', test.target, 'targetCS:', test.target.cs);
    for (const index in globalFlags.connectionStatusType) {
      if (link.target.cs === globalFlags.connectionStatusType[index]) {
        return globalFlags.connectionStatusColor[index];
      }
    }
    return globalFlags.connectionStatusColor[globalFlags.connectionStatusColor.length - 1];
  }
}

//ReprentationId, ASN, Capacity, etc...
