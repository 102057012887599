import { useEffect, useState } from 'react';
import { PanelData } from '@grafana/data';
import {
  validDataMask,
  getFieldDataByName,
  getCleanedFieldsByTier,
  checkLinks,
  GraphDataInterface,
  setGraphNodesLinks,
  cleanUpData,
} from './../nodeutils';
import { TierDataStructure } from './../data/fields';
import { format, differenceInCalendarDays } from 'date-fns';

const useGraphData = (initialData: PanelData, researchMode: boolean) => {
  const [graphData, setGraphData] = useState<any>(null);
  const [advanceTier, setAdvancedTier] = useState<boolean>(false);
  const [monthTimeRange, setMonthTimeRange] = useState<boolean>(false);
  const [graphDateInfo, setGraphDateInfo] = useState<string>('');

  useEffect(() => {
    const processGraphData = () => {
      // Process graph data
      const dataFields = initialData?.series[0]?.fields || [];
      const isAdvancedTier = dataFields.length > 6;
      setAdvancedTier(isAdvancedTier); // set flag if it is advance or lite tier graph

      const cleanDataMask = validDataMask(getFieldDataByName({ json: dataFields, fieldName: 'NodeId' }));
      const cleanedFields: TierDataStructure = getCleanedFieldsByTier(
        researchMode ? 'research' : isAdvancedTier ? 'advance' : 'lite',
        dataFields,
        cleanDataMask
      );
      cleanedFields.sourceValues = checkLinks(cleanedFields?.idValues, cleanedFields?.sourceValues);
      const gData: GraphDataInterface = setGraphNodesLinks(
        cleanedFields,
        researchMode ? 'research' : isAdvancedTier ? 'advance' : 'lite'
      );      
      const numNodes = cleanedFields.sourceValues.length // Number of nodes in the panel
      setGraphData(gData);
      // end process graph data

      // catch last date from data to show it in the graph info box
      let graphDate = '';
      let timestampRows: string[] | number[] = cleanUpData(
        getFieldDataByName({ json: dataFields, fieldName: 'timestamp' }),
        cleanDataMask
      );

      if (timestampRows.length) {
        timestampRows = timestampRows.map(Number);
        const maxTimestamp = Math.max(...timestampRows);
        graphDate = format(new Date(maxTimestamp), 'yyyy-MM-dd HH:mm:ss') + ' UTC :: N = ' + numNodes;
      }
      setGraphDateInfo(graphDate);
    };

    const calcTimeRange = () => {
      // calc if grafana filter range is either around last 30 days or not
      const timeRangeFrom = initialData?.timeRange?.from;
      const timeRangeTo = initialData?.timeRange?.to;
      let timeRangeWithin = false;

      if (typeof timeRangeFrom.toDate === 'function' && typeof timeRangeTo.toDate === 'function') {
        const diffTimeRangeFrom = differenceInCalendarDays(new Date(timeRangeFrom.toDate()), new Date());
        const diffTimeRangeTo = differenceInCalendarDays(new Date(timeRangeTo.toDate()), new Date());
        timeRangeWithin = diffTimeRangeFrom <= 0 && diffTimeRangeFrom >= -30 && diffTimeRangeTo >= 0;
      }

      setMonthTimeRange(timeRangeWithin);
    };

    if (initialData?.state === 'Done') {
      processGraphData();
      calcTimeRange();
    }
  }, [initialData.state, initialData.series, initialData.timeRange, researchMode]);

  return [graphData, advanceTier, monthTimeRange, graphDateInfo];
};

export default useGraphData;
