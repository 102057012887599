/*
  ---< Definition of Global variables >---
*/
import chroma from 'chroma-js';

interface GlobalFlags {
  // POI
  poiId: string;
  poiColor: string;
  poiLevel: number;
  poiNodeSize: number;

  // GhostNode
  ghostId: string;
  ghostColor: string;
  ghostLevel: number;
  ghostNodeSize: number;
  useGhostNode: boolean;

  // General Nodes
  nodeErrorColor: string;
  nodeSize: number;
  highlightSize: number;
  nodeHighlightColor: string;

  // General Links
  linkColor: string;
  linkWidth: number;
  linkErrorColor: string;
  linkHighlightColor: string;

  // ABFR
  aggregateBufferFillingRate: number[];
  aggregateBufferFillingRateColor: string[];
  progressBarMaxaggregateBufferFillingRate: number;

  // BufferHealth
  bufferHealth: number[];
  bufferHealthColor: string[];
  progressBarMaxBufferHealth: number;

  // NatType
  natType: string[];
  natTypeColor: string[];

  // ConnectionStatus
  connectionStatusType: string[];
  connectionStatusColor: string[];

  // Forces
  chargeForce: number;
  collideLevelWeight: number;
  collideRadius: number;

  linkForce: number;
  linkDistance: number;
  linkDistanceLevelWeight: number;

  velocityDecay: number;
  zoomToFitDelayMs: number;
  cooldownTimeMs: number;

  maxNumberTreeLevels: number;
  nodeRelativeSize: number;

  firstTimeZoom: boolean;

  // Miscelanea
  maxSizeTooltipString: number;
}

// https://www.w3.org/TR/css-color-3/
export const globalFlags: GlobalFlags = {
  // POI
  poiId: 'CDN',
  poiColor: chroma('cornflowerblue').hex(),
  poiLevel: 1,
  poiNodeSize: 15,

  // Ghost Node
  ghostId: 'ghostNode',
  ghostColor: chroma('white').hex(),
  ghostLevel: 1,
  ghostNodeSize: 2,
  useGhostNode: true,

  // General Nodes
  nodeErrorColor: chroma('white').hex(),
  nodeSize: 2,
  highlightSize: 7,
  nodeHighlightColor: chroma('orange').hex(),

  // General Links
  linkColor: chroma('white').hex(),
  linkWidth: 2,
  linkErrorColor: chroma('white').hex(),
  linkHighlightColor: chroma('orange').hex(),

  // ABFR [x.yy float * 100]
  aggregateBufferFillingRate: [1, 180, 360],
  aggregateBufferFillingRateColor: [
    chroma('red').hex(),
    chroma('darkred').hex(),
    chroma('lightgreen').hex(),
    chroma('teal').hex(),
  ],
  progressBarMaxaggregateBufferFillingRate: 360,

  // BufferHealth [s]
  bufferHealth: [5, 10, 15, 20],
  bufferHealthColor: [
    chroma('red').hex(),
    chroma('darkred').hex(),
    chroma('khaki').hex(),
    chroma('lightgreen').hex(),
    chroma('teal').hex(),
  ],
  progressBarMaxBufferHealth: 30,

  // NatType
  natType: ['NON_SYMMETRIC', 'SYMMETRIC', 'SEQUENTIAL', 'UNKNOWN'],
  natTypeColor: [chroma('teal').hex(), chroma('red').hex(), chroma('fuchsia').hex(), chroma('yellow').hex()],

  // ConnectionStatus
  connectionStatusType: [
    'P2P_CONNECTED',
    'NO_PARENT',
    'P2P_CONNECTING',
    'UNKNOWN_STATUS',
    'CONTENT_NOT_SUPPORTED',
    'MEDIA_SOURCE_EXTENSIONS_NOT_SUPPORTED',
  ],
  connectionStatusColor: [
    chroma('teal').hex(),
    chroma('red').hex(),
    chroma('fuchsia').hex(),
    chroma('yellow').hex(),
    chroma('lightgreen').hex(),
    chroma('aqua').hex(),
  ],

  // Forces
  chargeForce: -10,

  collideLevelWeight: 2.05,
  collideRadius: 80,

  linkForce: 10,
  linkDistance: 30,
  linkDistanceLevelWeight: 1.5,

  velocityDecay: 0.15,
  zoomToFitDelayMs: 200,
  cooldownTimeMs: 3000,

  maxNumberTreeLevels: 30,
  nodeRelativeSize: 1,

  firstTimeZoom: true,

  // Miscelanea
  maxSizeTooltipString: 16,
};
