/*
  ---< GUI related functions >---
*/
// Convert Custom Layout menu options to 3d-graph options
export function getLayout(value: string) {
  switch (value) {
    case 'Top-Down':
      return 'td';
    case 'Globular':
      return null;
  }
  return null;
}
