import { useEffect, useState } from 'react';
import { EventBus } from '@grafana/data';
import { getTemplateSrv, RefreshEvent } from '@grafana/runtime'; // Access to Dashboard Varibles

/**
 * Runtime Variables
 * @param eventBus
 * @param varName
 */

// Read variables from Dashboard
const getVariableValue = (variableName: string) => {
  const variables = getTemplateSrv().getVariables();
  const varValues = variables.find((v) => v.name === variableName) as any;
   
  if (!varValues || varValues.current.value === undefined) {
    return null;
  }

  return varValues.current.value;
};

export const useRuntimeVariable = (eventBus: EventBus, varName: string) => {
  // Reading Dashboard variable value when loading
  const [currentValue, setCurrentValue] = useState<string>(getVariableValue(varName));

  useEffect(() => {
    // Getting the value when refresh
    const subscriber = eventBus.getStream(RefreshEvent).subscribe(() => {
      const refreshedVariableValue = getVariableValue(varName);
      setCurrentValue(refreshedVariableValue);
    });

    // Clean subscriber
    return () => {
      subscriber.unsubscribe();
    };
  }, [eventBus, varName]);

  return {
    currentValue,
  };
};
