import React, { useEffect, useRef, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions, InfoBoxItem } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import ForceGraph3d from 'react-force-graph-3d';

//====== custom plugin
import useGraphData from 'hooks/useGraphData';
import { MessageModal } from './MessageModal';
import { InfoBox } from './InfoBox';
import GUI from 'lil-gui';
import { getLayout } from 'interface/gui-extra';
import { globalFlags } from 'globalflags';
import { NodeColorSelector, nodeColorSelectorFactory, PolynetNodeObject3D } from 'nodeColorStrategies';
import { LinkColorSelector, linkColorSelectorFactory, PolynetLinkObject3D } from 'linkColorStrategies';
//import { LinkColorSelector, linkColorSelectorFactory } from 'linkColorStrategies2';
import 'style.css';
import { useRuntimeVariable } from '../hooks/useRuntimeVariable';
//====

interface Props extends PanelProps<SimpleOptions> {}

const NO_DATA_WITHIN_30_DAYS = 'There is no data to display for the selected end of time interval.';
const NO_DATA_OUT_OF_RANGE = 'Please select an interval ending within the last 30 days for this chart.';

let highlightNodeId: string | undefined;
let researchMode = false;
let guiTitle: string | undefined;

export const SimplePanel: React.FC<Props> = ({ width, height, data, eventBus }) => {
  // --- Reading Dashboard variables
  const getResearchMode = useRuntimeVariable(eventBus, 'researchMode');
  researchMode = Boolean(JSON.parse(getResearchMode.currentValue));
  // ---

  const topologyRef = useRef<any>();
  const styles = useStyles2(getStyles);
  const [graphData, advanceTier, monthTimeRange, graphDateInfo] = useGraphData(data, researchMode);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState('');
  const [infoBoxData, setInfoBoxData] = useState<InfoBoxItem[]>([]);

  // -- GUI defualt values
  const [metricState, setMetricState] = useState({ metric: 'BufferHealth' });
  const [nodeState, setNodeState] = useState({ nodes: 'BufferHealth' }); // Initial condition can't be from ResearchMode!
  const [linkState, setLinkState] = useState({ links: 'ConnectionStatus' });
  const [layoutState, setLayoutState] = useState({ layout: 'Top-Down' });
  const nodeColorSelector = new NodeColorSelector(nodeColorSelectorFactory(nodeState.nodes));
  const linkColorSelector = new LinkColorSelector(linkColorSelectorFactory(linkState.links));

  const nodeColorCallback = (node: any) => {
    nodeColorSelector.setColorStrategy(nodeColorSelectorFactory(nodeState.nodes));
    return nodeColorSelector.colorFunction(node as PolynetNodeObject3D, highlightNodeId);
  };

  const linkColorCallback = (link: any) => {
    if (researchMode) {
      linkColorSelector.setColorStrategy(linkColorSelectorFactory(linkState.links));
      //console.log('callback:', linkState.links, linkColorSelector.colorFunction(link as PolynetLinkObject3D));
      return linkColorSelector.colorFunction(link as PolynetLinkObject3D);
    } else {
      return globalFlags.linkColor;
    }
  };

  const nodeDragEndCallback = (node: any) => {
    const myNode = node as PolynetNodeObject3D;
    myNode.fx = myNode.x;
    myNode.fy = myNode.y;
    myNode.fz = myNode.z;
  };

  const onEngineStopCallback = () => {
    // Make sure topologyRef.current is not null before trying to call zoomToFit.
    if (globalFlags.firstTimeZoom && topologyRef.current) {
      topologyRef.current.zoomToFit(globalFlags.zoomToFitDelayMs);
      globalFlags.firstTimeZoom = false;
    }
  };

  const handleNodeClick = (node: any) => {
    const myNode = node as PolynetNodeObject3D;
    if (myNode.id === globalFlags.poiId) {
      highlightNodeId = '%';
    } else {
      highlightNodeId = myNode.id as string;
    }
    topologyRef.current.refresh();
  };

  const researchMetricMenu = (gui: any) => {
    const nodeOptions = ['ABFR', 'BufferHealth', 'NatType', 'ConnectionStatus'];
    const linkOptions = ['ABFR', 'BufferHealth', 'NatType', 'ConnectionStatus'];
    const layoutOptions = ['Top-Down', 'Globular'];
    gui
      .add(nodeState, 'nodes', nodeOptions) // Node menu
      .onChange((nodes: string) => {
        setNodeState({ nodes });
      });
    gui
      .add(linkState, 'links', linkOptions) // Link menu
      .onChange((links: string) => setLinkState({ links }));
    gui
      .add(layoutState, 'layout', layoutOptions) // Layout menu
      .onChange((layout: string) => setLayoutState({ layout }));
  };

  const defaultMetricMenus = (gui: any) => {
    if (advanceTier) {
      const menuOptions = ['ABFR', 'BufferHealth', 'NatType'];
      gui
        .add(metricState, 'metric', menuOptions) // Metrics menu
        .onChange((metric: string) => {
          setMetricState({ metric });
        });
    }
    gui
      .add(layoutState, 'layout', ['Top-Down', 'Globular']) // Layout menu
      .onChange((layout: string) => setLayoutState({ layout }));
  };

  useEffect(() => {
    if (researchMode) {
      guiTitle = 'Controls - Research Mode';
    } else {
      guiTitle = 'Controls';
    }
    const params = { autoPlace: false, title: guiTitle };
    const gui = new GUI(params);
    gui.domElement.id = 'gui';
    if (researchMode) {
      researchMetricMenu(gui);
    } else {
      defaultMetricMenus(gui);
    }

    const htmlMenu = document.querySelector('#gui');
    if (htmlMenu) {
      document.getElementById('3d-graph')?.removeChild(htmlMenu);
    }

    if (graphData?.nodes?.length <= 1) {
      setNoDataFlag(true);
      if (monthTimeRange === true) {
        setNoDataMessage(NO_DATA_WITHIN_30_DAYS);
      } else {
        setNoDataMessage(NO_DATA_OUT_OF_RANGE);
      }
      setInfoBoxData([]);
    } else {
      document.getElementById('3d-graph')?.appendChild(gui.domElement);
      setNoDataFlag(false);
      setNoDataMessage('');
      if (graphDateInfo !== '') {
        setInfoBoxData([
          {
            label: 'Date:',
            value: graphDateInfo,
            showLabel: false,
          },
        ]);
      }
    }
  }, [graphData, advanceTier, monthTimeRange, graphDateInfo]);

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div id="3d-graph">
        <ForceGraph3d
          ref={topologyRef}
          graphData={graphData ? graphData : { nodes: [], links: [] }}
          nodeVal={(node: any) => (node as PolynetNodeObject3D).size}
          linkWidth={globalFlags.linkWidth}
          height={height}
          width={width}
          nodeId="id"
          nodeLabel="tooltip"
          //@ts-ignore
          dagMode={getLayout(layoutState['layout'])}
          d3VelocityDecay={globalFlags.velocityDecay}
          cooldownTime={globalFlags.cooldownTimeMs}
          onEngineStop={onEngineStopCallback}
          showNavInfo={false}
          linkColor={linkColorCallback}
          nodeColor={nodeColorCallback}
          onNodeClick={handleNodeClick}
          onNodeDragEnd={nodeDragEndCallback}
        />
      </div>
      <InfoBox items={infoBoxData} containerWidth={width} />
      <MessageModal show={noDataFlag} message={noDataMessage} />
    </div>
  );
};

const getStyles = () => ({
  wrapper: css`
    font-family: Open Sans;
    position: relative;
  `,
  svg: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
  textBox: css`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
  `,
});
