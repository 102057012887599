/*
    ---< Context and Strategy interface definitions >---

- NodeColorSelector: context class
- NodeColorSelectorStrategy: interface used by the context

- PolynetNodeObject3D: extension of the PolynetNodeObject with metrics
- nodeColorSelectorFactory: strategies selector according to metric
- NodeColorABFR: color strategy for "AggregateBufferFillingRate"
- NodeColorBufferHealth: color strategy for "BufferHealth"
- NodeColorNatType: color strategy for "NatType"

*/

import { globalFlags } from './globalflags';
import { PolynetNodeObject } from './nodeutils';

// ---< Context Section >---

// NodeColorSelector context definition
export class NodeColorSelector {
  private colorStrategy: NodeColorSelectorStrategy;

  constructor(strategy: NodeColorSelectorStrategy) {
    this.colorStrategy = strategy;
  }

  setColorStrategy(strategy: NodeColorSelectorStrategy) {
    this.colorStrategy = strategy;
  }

  // Common part to all strategies
  colorFunction(node: PolynetNodeObject3D, highlightNodeId: string | undefined): string {
    if (highlightNodeId && highlightNodeId === node.id) {
      return globalFlags.nodeHighlightColor;
    }
    if (!node) {
      return globalFlags.nodeErrorColor;
    }
    if (node.id === globalFlags.poiId) {
      return globalFlags.poiColor;
    }
    if (node.id === globalFlags.ghostId) {
      return globalFlags.ghostColor;
    }

    return this.colorStrategy.getHexColor(node);
  }
}

// NodeColorSelectorStrategy interface
interface NodeColorSelectorStrategy {
  getHexColor(node: PolynetNodeObject3D): string;
}

// ---< Strategies Section >---
// Edit this section to add more strategies

// Extension of the interface PolynetNodeObject with the metrics to color nodes
export interface PolynetNodeObject3D extends PolynetNodeObject {
  abfr: number;
  bh: number;
  nat: string;
  cs: string;
}

// Strategy factory selector
export function nodeColorSelectorFactory(metric: string): NodeColorSelectorStrategy {
  switch (metric) {
    case 'ABFR':
      return new NodeColorABFR();
    case 'BufferHealth':
      return new NodeColorBufferHealth();
    case 'NatType':
      return new NodeColorNatType();
    case 'ConnectionStatus':
      return new NodeColorConnectionStatus();
    default:
      return new NodeColorBufferHealth();
  }
}

// ABFR
class NodeColorABFR implements NodeColorSelectorStrategy {
  getHexColor(node: PolynetNodeObject3D): string {
    for (const index in globalFlags.aggregateBufferFillingRate) {
      if (node.abfr < globalFlags.aggregateBufferFillingRate[index]) {
        return globalFlags.aggregateBufferFillingRateColor[index];
      }
    }
    return globalFlags.aggregateBufferFillingRateColor[globalFlags.aggregateBufferFillingRateColor.length - 1];
  }
}

// BufferHealth
class NodeColorBufferHealth implements NodeColorSelectorStrategy {
  getHexColor(node: PolynetNodeObject3D): string {
    for (const index in globalFlags.bufferHealth) {
      if (node.bh < globalFlags.bufferHealth[index]) {
        return globalFlags.bufferHealthColor[index];
      }
    }
    return globalFlags.bufferHealthColor[globalFlags.bufferHealthColor.length - 1];
  }
}

// NatType
class NodeColorNatType implements NodeColorSelectorStrategy {
  getHexColor(node: PolynetNodeObject3D): string {
    for (const index in globalFlags.natType) {
      if (node.nat === globalFlags.natType[index]) {
        return globalFlags.natTypeColor[index];
      }
    }
    return globalFlags.natTypeColor[globalFlags.natTypeColor.length - 1];
  }
}

// ConnectionStatus
class NodeColorConnectionStatus implements NodeColorSelectorStrategy {
  getHexColor(node: PolynetNodeObject3D): string {
    for (const index in globalFlags.connectionStatusType) {
      if (node.cs === globalFlags.connectionStatusType[index]) {
        return globalFlags.connectionStatusColor[index];
      }
    }
    return globalFlags.connectionStatusColor[globalFlags.connectionStatusColor.length - 1];
  }
}
// ReprentationId, ASN, Capacity, etc...
