import React from 'react';
import { MessageModalProps } from 'types';

export const MessageModal: React.FC<MessageModalProps> = ({ show, message }) => {
  return <>
    {show === true &&
      <div className="modal-container">
        <p>{message}</p>
      </div>
    }
  </>
};
