/* 
      ---< Tooltip >---

  Generation of a Tooltip with Node information (Check Tier restrictions)

  - NodeId
  - AggregateBufferFillingRate
  - BufferHealth
  - Capacity
  - P2PConnectionStatus
  - NatType
  - IP
  - AsNname

*/
import { TierDataStructure } from '../data/fields';
import { globalFlags } from '../globalflags';

function bhProgressColor(value: number) {
  for (const index in globalFlags.bufferHealth) {
    if (value < globalFlags.bufferHealth[index]) {
      return `accent-color: ${globalFlags.bufferHealthColor[index]}`;
    }
  }
  return `accent-color: ${globalFlags.bufferHealthColor[globalFlags.bufferHealthColor.length - 1]}`;
}

function abfrProgressColor(value: number) {
  for (const index in globalFlags.aggregateBufferFillingRate) {
    if (value < globalFlags.aggregateBufferFillingRate[index]) {
      return `accent-color: ${globalFlags.aggregateBufferFillingRateColor[index]}`;
    }
  }
  return `accent-color: ${globalFlags.aggregateBufferFillingRateColor[globalFlags.aggregateBufferFillingRateColor.length - 1]
    }`;
}

function getProgressTagHtml(style_fn: (value: number) => string, value: number, max: number) {
  return `<progress style="${style_fn(value)}" value="${value}" max="${max}"> ${value} </progress>`;
}

function getConnectionStatusEmoji(value: string) {
  switch (value) {
    case 'UNKNOWN':
      return '&#x2753'; // Red Octogone
    case 'P2P_CONNECTING':
      return '&#x274E'; // Green X
    case 'P2P_CONNECTED':
      return '&#x2705'; // green V
    case 'NO_PARENT':
      return '&#x1F6D1'; // red X
  }
  return '';
}

function getNatTypeString(value: string) {
  switch (value) {
    case 'UNKNOWN':
      return '&#x2753'; // Red ?
    case 'NON_SYMMETRIC':
      return 'NON_SYMMETRIC';
    case 'SYMMETRIC':
      return 'SYMMETRIC';
    case 'SEQUENTIAL':
      return 'SEQUENTIAL';
  }
  return '';
}

function formatNumberLength(num: string | number, length = 0): string {
  if (!num.toString().includes('.')) {
    return num.toString();
  }

  const integer = num.toString().split('.')[0];
  const decimals = num.toString().split('.')[1];
  if (integer.length > length - 1) {
    return integer;
  }
  if (integer.length > length - 2) {
    return integer + '.' + decimals.slice(0, 1);
  }

  return integer + '.' + decimals.slice(0, 2);
}

function truncateString(inputString: string, maxSize: number): string {
  if (inputString.length > maxSize) {
    return inputString.substring(0, maxSize) + '...';
  }
  return inputString;
}

// -- ADVANCE
export function generateTooltipAdvanced(cleanedFields: TierDataStructure, index: number) {
  const shortIdInit = cleanedFields.idValues[index].slice(0, 6);
  const shortIdEnd = cleanedFields.idValues[index].slice(-6);
  const shortSessionIdInit =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(0, 6) : '';
  const shortSessionIdEnd =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(-6) : '';

  // Add ABFR
  const abfrProgressBar = getProgressTagHtml(
    abfrProgressColor,
    cleanedFields.abfrValues !== undefined ? parseInt(cleanedFields.abfrValues[index], 10) : 0,
    globalFlags.progressBarMaxaggregateBufferFillingRate
  );
  const abfrValue =
    cleanedFields.abfrValues !== undefined
      ? formatNumberLength(parseInt(cleanedFields.abfrValues[index], 10) / 100, 4)
      : 0;

  // Add BufferHealth
  const bhProgressBar = getProgressTagHtml(
    bhProgressColor,
    cleanedFields.bufferHealthValues !== undefined ? parseInt(cleanedFields.bufferHealthValues[index], 10) : 0,
    globalFlags.progressBarMaxBufferHealth
  );
  const bhValue =
    cleanedFields.bufferHealthValues !== undefined ? formatNumberLength(cleanedFields.bufferHealthValues[index]) : '';

  // Add UpLinkCapacity
  const capacityString = cleanedFields.uplinkCapacity !== undefined ? cleanedFields.uplinkCapacity[index] : '';

  // ConnectionStatus Emoji
  const csEmoji =
    cleanedFields.connectionStatus !== undefined ? getConnectionStatusEmoji(cleanedFields.connectionStatus[index]) : '';

  // Add NAT
  const natTypeString = cleanedFields.natType !== undefined ? getNatTypeString(cleanedFields.natType[index]) : '';

  // Add IP
  const ipString = cleanedFields.ip !== undefined ? cleanedFields.ip[index] : '';
  // Add ASN NAME
  const asnString =
    cleanedFields.asname !== undefined
      ? truncateString(cleanedFields.asname[index], globalFlags.maxSizeTooltipString)
      : '';

  return `&#160;&#160;&#160;<b>SessionId:</b> </b> ${shortSessionIdInit}..${shortSessionIdEnd}<br>
  &#160;- <b>NodeId:</b> ${shortIdInit}..${shortIdEnd}<br>
  &#160;- <b>ABFR:</b> ${abfrProgressBar} ${abfrValue} <br>
  &#160;- <b>BH:</b> &#160;&#160;${bhProgressBar} ${bhValue} [s]<br>
  &#160;- <b>Capacity:</b> ${capacityString} <br>
  &#160;- <b>P2PConnectionStatus:</b> ${csEmoji}<br>
  &#160;- <b>NatType:</b> ${natTypeString}<br>
  &#160;- <b>IP:</b> ${ipString}<br>
  &#160;- <b>ASN:</b> ${asnString}<br>
  `;
}

// -- LITE
export function generateTooltipLite(cleanedFields: TierDataStructure, index: number) {
  const shortIdInit = cleanedFields.idValues[index].slice(0, 6);
  const shortIdEnd = cleanedFields.idValues[index].slice(-6);
  const shortSessionIdInit =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(0, 6) : '';
  const shortSessionIdEnd =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(-6) : '';

  // Add BufferHealth
  const bhProgressBar = getProgressTagHtml(
    bhProgressColor,
    cleanedFields.bufferHealthValues !== undefined ? parseInt(cleanedFields.bufferHealthValues[index], 10) : 0,
    globalFlags.progressBarMaxBufferHealth
  );
  const bhValue =
    cleanedFields.bufferHealthValues !== undefined ? formatNumberLength(cleanedFields.bufferHealthValues[index]) : 0;

  return `&#160;&#160;&#160;<b>SessionId:</b> </b> ${shortSessionIdInit}..${shortSessionIdEnd}<br>
  &#160;- <b>NodeId:</b> ${shortIdInit}..${shortIdEnd}<br>
  &#160;- <b>BH:</b> &#160;&#160;${bhProgressBar} ${bhValue} [s]<br>
  `;
}

// -- RESEARCH
export function generateTooltipResearch(cleanedFields: TierDataStructure, index: number) {
  const shortIdInit = cleanedFields.idValues[index].slice(0, 6);
  const shortIdEnd = cleanedFields.idValues[index].slice(-6);
  const shortSessionIdInit =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(0, 6) : '';
  const shortSessionIdEnd =
    cleanedFields.sessionValues !== undefined ? cleanedFields.sessionValues[index].slice(-6) : '';

  // Add ABFR
  const abfrProgressBar = getProgressTagHtml(
    abfrProgressColor,
    cleanedFields.abfrValues !== undefined ? parseInt(cleanedFields.abfrValues[index], 10) : 0,
    globalFlags.progressBarMaxaggregateBufferFillingRate
  );
  const abfrValue =
    cleanedFields.abfrValues !== undefined
      ? formatNumberLength(parseInt(cleanedFields.abfrValues[index], 10) / 100, 4)
      : 0;

  // Add BufferHealth
  const bhProgressBar = getProgressTagHtml(
    bhProgressColor,
    cleanedFields.bufferHealthValues !== undefined ? parseInt(cleanedFields.bufferHealthValues[index], 10) : 0,
    globalFlags.progressBarMaxBufferHealth
  );
  const bhValue =
    cleanedFields.bufferHealthValues !== undefined ? formatNumberLength(cleanedFields.bufferHealthValues[index]) : '';

  // Add UpLinkCapacity
  const capacityString = cleanedFields.uplinkCapacity !== undefined ? cleanedFields.uplinkCapacity[index] : '';

  // ConnectionStatus Emoji
  const csEmoji =
    cleanedFields.connectionStatus !== undefined ? getConnectionStatusEmoji(cleanedFields.connectionStatus[index]) : '';
  // Add ConnectionStatus
  const csTypeString = cleanedFields.connectionStatus !== undefined ? cleanedFields.connectionStatus[index] : '';

  // Add NAT
  const natTypeString = cleanedFields.natType !== undefined ? getNatTypeString(cleanedFields.natType[index]) : '';

  // Add IP
  const ipString = cleanedFields.ip !== undefined ? cleanedFields.ip[index] : '';
  
  // Add ASN NAME
  const asnString =
    cleanedFields.asname !== undefined
      ? truncateString(cleanedFields.asname[index], globalFlags.maxSizeTooltipString)
      : '';

  return `&#160;&#160;&#160;<b>id:</b> ${shortIdInit}..${shortIdEnd}<br>
  &#160;- <b>ABFR:</b> ${abfrProgressBar} ${abfrValue} <br>
  &#160;- <b>BH:</b> &#160;&#160;${bhProgressBar} ${bhValue} [s]<br>
  &#160;- <b>Capacity:</b> ${capacityString} <br>
  &#160;- <b>P2PConnectionStatus:</b> ${csEmoji}<br>
  &#160;- <b>CS:</b> ${csTypeString}<br>
  &#160;- <b>NatType:</b> ${natTypeString}<br>
  &#160;- <b>IP:</b> ${ipString}<br>
  &#160;- <b>ASN:</b> ${asnString}<br>
  &#160;- <b>SessionId:</b> </b> ${shortSessionIdInit}..${shortSessionIdEnd}<br>
  `;
}
